import React, { createRef } from 'react';
import { AboutUs, FindUs, Header, Intro, SpecialMenu } from './container';
import { Navbar } from './components';
import './App.css';

const App = () => {
    const menuRef = createRef();

    const scrollToMenu = () => {
        menuRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div>
            <Navbar />
            <Header scrollToMenu={scrollToMenu} />
            <SpecialMenu ref={menuRef} />
            <Intro />
            <AboutUs />
            <FindUs />
        </div>
    );
};

export default App;