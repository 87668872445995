import bg from '../assets/bg.png';
import overlaybg from '../assets/overlaybg.png';
import spoon from '../assets/spoon.svg';
import chicken from '../assets/Chicken.jpg';
import gChicken from '../assets/gChicken.jpg';
import butikken from '../assets/butikken.png';
import logo from '../assets/logo.png';
import line from '../assets/linenew.png';
import smiley from '../assets/image.png';

export default {
    bg,
    overlaybg,
    spoon,
    chicken,
    gChicken,
    butikken,
    logo,
    line,
    smiley
};
