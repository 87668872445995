import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import './Findus.css';
import { images } from '../../constants';
import {SubHeading} from "../../components";

const ContactForm = () => {
    const [state, handleSubmit] = useForm("mwkdwznb"); // Kun hvis de beder om det

    if (state.succeeded) {
        return <p>Tak for din email. Vi vender tilbage hurtigst muligt!</p>;
    }

    return (
        <form onSubmit={handleSubmit}>
            <input
                id="email"
                type="email"
                name="email"
                placeholder="Din Email"
                required
            />
            <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
            />
            <textarea
                id="message"
                name="message"
                placeholder="Din Besked"
                required
            />
            <ValidationError
                prefix="Message"
                field="message"
                errors={state.errors}
            />
            <button className="custom__button" type="submit" disabled={state.submitting} >
                Send
            </button>
        </form>
    );
};



const FindUs = () => (
    <div className="app__bg app__wrapper section__padding" id="contact">
        <div className="app__wrapper_info">
            <SubHeading title="Kontakt"  />
            <h1 className="headtext__cormorant" style={{marginBottom: '3rem'}}>Find os</h1>
            <div className="app__wrapper-content">
                <p className="p__opensans-about">Kongelundsvej 260, 2770 Kastrup</p>
                <p className="p__opensans-about">Telefon:{' '}
                    <a href="tel:32526466" className="phone-number"
                       title="Klik for at ringe til os">
                        32 52 64 66
                    </a>
                </p>
                <p className="p__opensans-about" style={{color: '#DCCA87', margin: '2rem 0'}}>Åbningstider</p>
                <p className="p__opensans-about">Man-Fre: 10:30 - 21:00 </p>
                <p className="p__opensans-about">Lør-Søn: 12:00 - 21:00 </p>
            </div>

            <a href="https://maps.app.goo.gl/yCo1c1qur2K3GnN48" target="_blank" rel="noopener noreferrer" style={{marginTop: '2rem', display: 'inline-block'}}>
                <button className="custom__button">Besøg os</button>
            </a>

            <a href="https://findsmiley.dk/969346" target="_blank" rel="noopener noreferrer" style={{ marginTop: '1rem' }}>
                <img src={images.smiley} alt="Food Control Report" className="food-control-report-img"/>
            </a>

        </div>
        <div className="app__wrapper_img">
            <img src={images.butikken} alt="find us"/>
        </div>
    </div>
);

export default FindUs;