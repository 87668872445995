import React, { useState, forwardRef } from 'react';
import { images, data } from '../../constants';
import { SubHeading, MenuItem } from '../../components';
import './SpecialMenu.css';

const SpecialMenu = forwardRef((props, ref) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [areLunchDealsVisible, setLunchDealsVisible] = useState(false);
    const [isNewProductsVisible, setNewProductsVisible] = useState(false); // State for toggling new products visibility

    const toggleMenu = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleLunchDeals = () => {
        setLunchDealsVisible(!areLunchDealsVisible);
    };

    const toggleNewProducts = () => {
        setNewProductsVisible(!isNewProductsVisible); // Toggle visibility of new products
    };

    const renderLeftAdditionalItems = (isMobile) => (
        <div className="app__specialMenu-additional-items">
            {/* Sandwich */}
            {Array.isArray(data.additionalSandwich) && (
                <>
                    <h2 className="additionals-heading">Sandwich</h2>
                    {data.additionalSandwich.map((sandwich, index) => (
                        <MenuItem key={sandwich.title + index} {...sandwich} />
                    ))}
                </>
            )}
            {/* Drinks */}
            {!isMobile && Array.isArray(data.drinks) && (
                <>
                    <h2 className="additionals-heading">Drinks</h2>
                    {data.drinks.map((drink, index) => (
                        <MenuItem key={drink.title + index} {...drink} />
                    ))}
                </>
            )}
        </div>
    );

    const renderNewProducts = () => (
        <>
            <h2 className="additionals-heading">Nyheder</h2>
            {data.newProduct.map((product, index) => (
                <MenuItem key={product.title + index} {...product} />
            ))}
        </>
    );

    const renderRightAdditionalItems = () => (
        <div className="app__specialMenu-additional-items">
            {/* Salater */}
            {Array.isArray(data.additionalSalats) && (
                <>
                    <h2 className="additionals-heading">Salater</h2>
                    {data.additionalSalats.map((salat, index) => (
                        <MenuItem key={salat.title + index} {...salat} />
                    ))}
                </>
            )}
            {/* Diverse */}
            {Array.isArray(data.diverse) && (
                <>
                    <h2 className="additionals-heading">Diverse</h2>
                    {data.diverse.map((item, index) => (
                        <MenuItem key={item.title + index} {...item} />
                    ))}
                </>
            )}
        </div>
    );

    const renderMobileDrinks = () => (
        <div className="app__specialMenu-additional-items">
            {/* Drinks */}
            {Array.isArray(data.drinks) && (
                <>
                    <h2 className="additionals-heading">Drinks</h2>
                    {data.drinks.map((drink, index) => (
                        <MenuItem key={drink.title + index} {...drink} />
                    ))}
                </>
            )}
        </div>
    );

    return (
        <div className="app__specialMenu flex__center section__padding" id="menu" ref={ref}>
            <div className="app__specialMenu-title">
                <SubHeading title="Vores faste menu" />
                <h1 className="headtext__cormorant">Menu</h1>
            </div>

            <div className="app__specialMenu-menu">
                <div className="app__specialMenu-menu_left">
                    <div className="app__specialMenu_menu_items">
                        {data.menusLeft.map((menusLeft, index) => (
                            <MenuItem key={menusLeft.title + index} {...menusLeft} />
                        ))}
                    </div>
                    {isExpanded && window.innerWidth > 650 && renderLeftAdditionalItems(false)}
                </div>

                <div className="app__specialMenu-menu_img">
                    <img src={images.gChicken} alt="menu img" />
                </div>

                <div className="app__specialMenu-menu_right">
                    <div className="app__specialMenu_menu_items">
                        {data.menusRight.map((menusRight, index) => (
                            <MenuItem key={menusRight.title + index} {...menusRight} />
                        ))}
                    </div>
                    {isExpanded && window.innerWidth > 650 && renderRightAdditionalItems()}
                </div>
            </div>

            {isExpanded && window.innerWidth <= 650 && (
                <div className="app__specialMenu-additional-items-mobile">
                    {renderLeftAdditionalItems(true)}
                    {renderRightAdditionalItems()}
                    {renderMobileDrinks()}
                </div>
            )}

            {/* Button to toggle new products */}
            <div style={{ marginTop: '15px' }}>
                <button type="button" className="custom__button" onClick={toggleNewProducts}>
                    {isNewProductsVisible ? 'Se mindre Nyheder' : 'Se Nyheder'}
                </button>
            </div>

            {/* New Products rendered here */}
            {isNewProductsVisible && (
                <div className="app__specialMenu-new-products">
                    {renderNewProducts()}
                </div>
            )}

            <div style={{ marginTop: '15px' }}>
                <button type="button" className="custom__button" onClick={toggleMenu}>
                    {isExpanded ? 'Se mindre' : 'Se resten af vores menu'}
                </button>
            </div>

            {/* Button for toggling Lunch Deals visibility */}
            <div style={{ marginTop: '15px' }}>
                <button type="button" className="custom__button" onClick={toggleLunchDeals}>
                    {areLunchDealsVisible ? 'Se mindre' : 'Se vores frokost tilbud'}
                </button>
            </div>

            {/* Conditional rendering of Lunch Deals section */}
            {areLunchDealsVisible && (
                <div className="app__specialMenu-lunch-deals">
                    <h2 className="additionals-heading">Frokost tilbud</h2>
                    <p className="p__opensans" style={{color: '#FFF', marginBottom: '35px'}}>Fra kl. 10:30 - 15:00 (Mandag - Fredag)</p>
                    {data.lunchDeals.map((deal, index) => (
                        <MenuItem key={deal.title + index} {...deal} />
                    ))}
                </div>
            )}
        </div>
    );
});

export default SpecialMenu;
