import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdOutlineRestaurantMenu } from "react-icons/md";
import images from "../../constants/images";
import "./Navbar.css";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const scrollToSection = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    setToggleMenu(false); // Close the mobile menu after clicking a link
  };

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.logo} alt="logo" />
      </div>
      <ul className="app__navbar-links">
        <li className="p__opensans">
          <a href="#home" onClick={() => scrollToSection("home")}>
            Hjem
          </a>
        </li>
        <li className="p__opensans">
          <a href="#menu" onClick={() => scrollToSection("menu")}>
            Menu
          </a>
        </li>
        <li className="p__opensans">
          <a href="#about" onClick={() => scrollToSection("about")}>
            Om os
          </a>
        </li>
        <li className="p__opensans">
          <a href="#contact" onClick={() => scrollToSection("contact")}>
            Kontakt
          </a>
        </li>
        <li className="p__opensans">
          <a href="https://www.eatonline.dk/restaurant/751/french-chicken-kastrup">
            Bestil Online
          </a>
        </li>
      </ul>

      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu
          color={"#545454"}
          fontSize={27}
          onClick={() => setToggleMenu(true)}
        />

        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <MdOutlineRestaurantMenu
              fontSize={27}
              className="overlay__close"
              onClick={() => setToggleMenu(false)}
            />
            <ul className="app__navbar-smallscreen_links">
              <li className="p__opensans">
                <a href="#home" onClick={() => scrollToSection("home")}>
                  Hjem
                </a>
              </li>
              <li className="p__opensans">
                <a href="#about" onClick={() => scrollToSection("about")}>
                  Om Os
                </a>
              </li>
              <li className="p__opensans">
                <a href="#menu" onClick={() => scrollToSection("menu")}>
                  Menu
                </a>
              </li>
              <li className="p__opensans">
                <a href="#contact" onClick={() => scrollToSection("contact")}>
                  Kontakt
                </a>
              </li>
              <li className="p__opensans">
                <a href="https://www.eatonline.dk/restaurant/751/french-chicken-kastrup">
                  Bestil Online
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
