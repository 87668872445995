import images from './images';

const menusLeft = [
  {
    title: 'Menu 1',
    price: '80 kr',
    tags: '1/2 kylling med enten lille pommes frites, eller bagt kartoffel',
  },
  {
    title: 'Menu 2',
    price: '90 kr',
    tags: '1/2 kylling med enten ovnbagte kartofler, flødekartofler, eller stor pommes frites ',
  },
  {
    title: 'Menu 3',
    price: '80 kr ',
    tags: '7 stk Hotwings med lille pommes frites, eller bagt kartoffel (+10kr flødekartofler)',
  },
  {
    title: 'Menu 4',
    price: '80 kr ',
    tags: '5 stk kyllingelår med lille pommes, eller bagt kartoffel (+10kr flødekartofler)',
  },
  {
    title: 'Menu 5',
    price: '94 kr ',
    tags: 'Flæskesteg med brun sauce. Tilbehør: kogte eller ovnbagte kartofler',
  },
  {
    title: 'Menu 6',
    price: '87 kr ',
    tags: 'Frikadeller med brun sauce. Tilbehør: kogte eller ovnbagte kartofler (+5kr flødekartofler) ',
  }
];

const menusRight = [
  {
    title: 'Menu 7',
    price: '97 kr ',
    tags: 'Flæskesteg med brun sauce, og flødekartofler',
  },

  {
    title: 'Menu 8',
    price: '90 kr',
    tags: '10 stk chicken nuggets. Tilbehør: stor pommes frites eller flødekartofler ',
  },
  {
    title: 'Menu 9',
    price: '95 kr',
    tags: '2 stk kyllinge brystfillet. Tilbehør: stor pommes frites eller flødekartofler ',
  },
  {
    title: 'Menu 10',
    price: '97 kr',
    tags: '1/2 kylling med lille salat ',
  },
  {
    title: 'Menu 11',
    price: '89 kr',
    tags: '2 stk fiskefillet med pommes frites, og remoulade',
  },
  {
    title: 'Børne menu',
    price: '65 kr',
    tags: 'Vælg mellem: 6 stk chicken nuggets, 3 stk kyllingelår, eller 1 stk. fiskefillet, med lille pommes frites',
  },
];



const additionalSandwich = [
  {
    title: 'Laks sandwich',
    price: '74 kr',
    tags: ''
  },

  {
    title: 'Bagel eller Flute sandwich',
    price: '65 kr',
    tags: 'Med kylling, bacon, og salat'
  },

  {
    title: 'Roastbeef sandwich',
    price: '70 kr',
    tags: 'Med ristede løg, agurkesalat, og remoulade'
  },

  {
    title: 'Flæskesteg sandwich',
    price: '64 kr',
    tags: 'Med rødkål, og agurkesalat'
  },

  {
    title: 'Frikadelle sandwich',
    price: '64 kr',
    tags: 'Med rødkål, agurkesalat, og remoulade'
  },

  {
    title: 'Flute med tun',
    price: '65 kr',
    tags: ''
  },
];

const additionalSalats = [
    {
        title: 'Bagt kartoffel',
        price: '74 kr',
        tags: 'Med kylling og salat'
    },

  {
    title: 'Salat - lille',
    price: '57 kr',
    tags: ''
  },
  
  {
    title: 'Salat - stor',
    price: '62 kr',
    tags: ''
  },

  {
    title: 'Kylling salat - lille',
    price: '64 kr',
    tags: ''
  },

  {
    title: 'Kylling salat - stor',
    price: '74 kr',
    tags: ''
  },

  {
    title: 'Pasta salat - lille ',
    price: '64 kr',
    tags: 'Med kylling'
  },

  {
    title: 'Pasta salat - stor',
    price: '74 kr',
    tags: 'Med kylling'
  },
  {
    title: 'Tun salat - lille',
    price: '64 kr',
    tags: ''
  },
  {
    title: 'Tun salat - stor',
    price: '74 kr',
    tags: ''
  },


];


const diverse = [
  {
    title: '1/2 kylling',
    price: '60 kr',
    tags: ''
  },

  {
    title: '1/1 kylling',
    price: '104 kr',
    tags: ''
  },

  {
    title: 'Hot wings',
    price: '9 kr',
    tags: 'Pr. stk'
  },

  {
    title: 'Kyllinglår',
    price: '10 kr',
    tags: 'pr. stk. Kan vælges med eller uden chilli'
  },

  {
    title: 'Nuggets',
    price: '44 kr',
    tags: '6 stk'
  },

  {
    title: 'Nuggets',
    price: '62 kr',
    tags: '10 stk'
  },

  {
    title: 'Pommes frites - lille',
    price: '38 kr',
    tags: ''
  },

  {
    title: 'Pommes frites - stor',
    price: '44 kr',
    tags: ''
  },

  {
    title: 'Bagt kartoffel',
    price: '29 kr',
    tags: 'Med persillesmør, eller dressing'
  },

  {
    title: 'Flødekartofler - lille',
    price: '45 kr',
    tags: ''
  },

  {
    title: 'Flødekartofler - stor',
    price: '75 kr',
    tags: ''
  },

  {
    title: 'Agurkesalat, jalapenos',
    price: '7 kr',
    tags: ''
  },

  {
    title: 'Dyppelse',
    price: '10 kr',
    tags: 'Ketchup, remoulade, mayonnaise, super-sauce, eller aioli'
  },

  {
    title: 'Ekstra bacon',
    price: '13 kr',
    tags: ''
  },

  {
    title: 'Tzatziki / Coleslaw / Brownies',
    price: '25 kr',
    tags: ''
  },

  {
    title: 'Brød',
    price: '15 kr',
    tags: 'Grovbrød, flute, eller bagel: sesam, grov, eller chilli cheddar'
  },
];

const drinks = [
  {
    title: 'Sodavand',
    price: '24 kr',
    tags: '0.5 liter'
  },

  {
    title: 'Sodavand',
    price: '37 kr',
    tags: '1.5 liter'
  },

  {
    title: 'Sodavand',
    price: '15 kr',
    tags: '0.33 liter (dåse)'
  },

  {
    title: 'Økologisk saft',
    price: '26 kr',
    tags: '0.5 liter'
  },

  {
    title: 'Kildevand',
    price: '15 kr',
    tags: '0.5 liter'
  },

  {
    title: 'Aloe Vera',
    price: '28 kr',
    tags: '0.5 liter'
  }];

  const lunchDeals =  [
    {
      title: 'Frokost tilbud 1' ,
      price: '62 kr',
      tags: 'Flute eller bagel sandwich, med kylling og bacon'
    },  

    {
      title: 'Frokost tilbud 2' ,
      price: '87 kr',
      tags: '1/2 kylling med lille salat'
    },  

    {
      title: 'Frokost tilbud 3' ,
      price: '80 kr',
      tags: 'Flute eller bagel sandwich, med 0.5 l sodavand'
    },  

    {
      title: 'Frokost tilbud 4' ,
      price: '60 kr',
      tags: 'Lille pasta salat med kylling'
    },  

    {
      title: 'Frokost tilbud 5' ,
      price: '59 kr',
      tags: 'Flæskesteg sandwich'
    },  

    {
      title: 'Frokost tilbud 6' ,
      price: '59 kr',
      tags: 'Frikadelle sandwich'
    },  

    ];

    const newProduct = [

      {
        title: 'Kyllingeinderfillet menu',
        price: '75 kr',
        tags: 'med lille pommes'
      },

      {
        title: 'Kyllingeinderfillet',
        price: '45 kr',
        tags: '3 stk'
      },

      {
        title: 'Kyllingeinderfillet',
        price: '75 kr',
        tags: '6 stk'
      },

      {
        title: 'Kyllingeinderfillet',
        price: '126 kr',
        tags: '12 stk'
      },

      {
        title: 'Chunks',
        price: '46 kr',
        tags: '6 stk'
      },

      {
        title: 'Chunks',
        price: '85 kr',
        tags: '12 stk'
      },

      {
        title: 'Chunks',
        price: '120 kr',
        tags: '20 stk'
      },
    ]


export default { menusLeft,menusRight, additionalSandwich, additionalSalats, diverse, drinks, lunchDeals, newProduct};
